<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>
                        {{ translate.instant("login") }}
                    </h2>
                    <ul>
                        <li>
                            <a routerLink="/">
                                {{ translate.instant("MainPage") }}
                            </a>
                        </li>
                        <li>{{ translate.instant("login") }}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Start Sign in Area -->
<div class="sign-in-area ptb-100">
    <div class="container">
        <div class="section-title">
            <img src="assets/img/logo.png" alt="logo" />
            <p>{{ translate.instant("JoinUs") }}</p>
        </div>

        <div class="sign-in-form">
            <form [formGroup]="loginForm" (submit)="onSubmit()" novalidate>
                <div class="form-group">
                    <input
                        class="form-control"
                        type="text"
                        formControlName="Email"
                        Email
                        [placeholder]="translate.instant('Email')"
                    />
                </div>
                <div
                    *ngIf="
                        loginForm.controls['Email'].invalid &&
                        (loginForm.controls['Email'].dirty ||
                            loginForm.controls['Email'].touched)
                    "
                    class="alert alert-danger"
                >
                    <div *ngIf="loginForm.controls['Email'].errors.required">
                        {{ translate.instant("EmailRequired") }}
                    </div>
                    <div *ngIf="loginForm.controls['Email'].errors.email">
                        {{ translate.instant("EmailWrongFormate") }}
                    </div>
                </div>

                <div class="form-group">
                    <input
                        type="password"
                        class="form-control"
                        formControlName="Password"
                        [placeholder]="translate.instant('Password')"
                    />
                </div>
                <div
                    *ngIf="
                        loginForm.controls['Password'].invalid &&
                        (loginForm.controls['Password'].dirty ||
                            loginForm.controls['Password'].touched)
                    "
                    class="alert alert-danger"
                >
                    <div *ngIf="loginForm.controls['Password'].errors.required">
                        {{ translate.instant("PasswordRequired") }}
                    </div>
                </div>
                <div class="row mb-5">
                    <div class="col-sm-6">
                        <p class="account-decs right">
                            <a
                                data-toggle="modal"
                                data-target="#ForgetPasswordModal"
                            >
                                {{ translate.instant("ForgetPassword") }}
                            </a>
                        </p>
                    </div>
                    <div class="col-sm-6">
                        <p class="account-decs left">
                            <a routerLink="/sign-up">
                                {{ translate.instant("NewAccount") }}
                            </a>
                        </p>
                    </div>
                </div>
                <div class="text-end">
                    <button
                        type="submit"
                        class="btn default-btn-one"
                    >
                        {{ translate.instant("login") }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Sign in  Area -->

<!-- Modal -->
<div
    class="modal fade"
    id="ForgetPasswordModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="ForgetPasswordModalLabel"
    aria-hidden="true"
>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="ForgetPasswordModalLabel">
                    {{ translate.instant("ForgetPassword") }}
                </h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form
                [formGroup]="forgetEmail"
                (submit)="onSubmitForgetPassword()"
                novalidate
            >
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <p>
                                {{ translate.instant("ForgetPasswordText") }}
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <input
                                    class="form-control"
                                    type="text"
                                    formControlName="Email"
                                    Email
                                    [placeholder]="translate.instant('Email')"
                                />
                            </div>
                            <div
                                *ngIf="
                                    forgetEmail.controls['Email'].invalid &&
                                    (forgetEmail.controls['Email'].dirty ||
                                        forgetEmail.controls['Email'].touched)
                                "
                                class="alert alert-danger"
                            >
                                <div
                                    *ngIf="
                                        forgetEmail.controls['Email'].errors
                                            .required
                                    "
                                >
                                    {{ translate.instant("EmailRequired") }}
                                </div>
                                <div
                                    *ngIf="
                                        forgetEmail.controls['Email'].errors
                                            .email"
                                >
                                    {{ translate.instant("EmailWrongFormate") }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                    >
                        {{ translate.instant("close") }}
                    </button>
                    <button
                        type="submit"
                        [disabled]="!forgetEmail.valid"
                        class="btn default-btn-one"
                    >
                        {{ translate.instant("submit") }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<div
    id="VerficationCodeModel"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="VerficationCodeModelLabel"
    aria-hidden="true"
>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="VerficationCodeModelLabel">
                    {{ translate.instant("VerficationCodeTosignin") }}
                </h5>
                <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <form
                [formGroup]="VerficationCodeForm"
                (submit)="onSubmitVerficationCodeForm()"
                novalidate
            >
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <p>
                                {{ translate.instant("VerficationCode") }}
                            </p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <input
                                    class="form-control"
                                    type="text"
                                    formControlName="VerficationCode"
                                    VerficationCode
                                    [placeholder]="translate.instant('VerficationCode')"
                                />
                            </div>
                            <div
                                *ngIf="
                                    VerficationCodeForm.controls['VerficationCode'].invalid &&
                                    (VerficationCodeForm.controls['VerficationCode'].dirty ||
                                        VerficationCodeForm.controls['VerficationCode'].touched)
                                "
                                class="alert alert-danger"
                            >
                                <div
                                    *ngIf="
                                        VerficationCodeForm.controls['VerficationCode'].errors
                                            .required
                                    "
                                >
                                    {{ translate.instant("VerficationCodeRequired") }}
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                    >
                        {{ translate.instant("close") }}
                    </button>
                    <button
                        type="submit"
                        [disabled]="!VerficationCodeForm.valid"
                        class="btn default-btn-one"
                    >
                        {{ translate.instant("submit") }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>