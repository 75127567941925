import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { getPatient, sessionIsExpired } from 'src/app/Functions/Utilities';
import { AppintmentAPIClient } from 'src/app/Services/appointment.service';

@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss'],
})
export class HomeOneComponent implements OnInit {
    PatientVisits: any[] = [];
    lastAppintment: any;
    constructor(
        public translate: TranslateService,
        private appintmentAPIClient: AppintmentAPIClient,
        private route: Router
    ) {}

    ngOnInit(): void {
        if (!sessionIsExpired()) {
            this.getPatientNextAppointment();
        }
    }
    sessionIsExpired() {
        return sessionIsExpired();
    }
    getPatientNextAppointment() {
        const appParam = {
            Patient_Code: getPatient()?.Patient_Code,
            FromDate: new Date().toISOString().split('T')[0],
            ToDate: new Date().toISOString().split('T')[0],
        };
        this.appintmentAPIClient
            .appintmentAPI_GetPatientAppointmentbydate(appParam)
            .subscribe((response: any) => {
                const currentDate = new Date();
                this.PatientVisits = response.PatientVisits;
                this.PatientVisits = [
                    {
                        Appo_ID: 15562,
                        Appo_Start: '2024-01-09T08:40:00',
                        Appo_End: '2024-01-14T02:02:42',
                        patient_code: '282143',
                        Patient_Name_Ar: 'السيد عبد المعطي إبراهيم محمد',
                        Patient_Name_En: 'ELSAYED ABDELMOTI IBRAHIM MOHAMED',
                        DoctorCode: '82',
                        DoctorNameAr: 'نهال الدمرداش',
                        DoctorNameEn: 'NEHAL ALDEMERDASH',
                        ClinicDept_Code: '31',
                        ClinicDept_Name_Ar: 'PHYSIOTHERAPY',
                        ClinicDept_Name_En: 'PHYSIOTHERAPY',
                        STATUS: 1,
                        StatusName_Ar: 'جديد',
                        StatusName_En: 'New',
                        Appo_Notes: null,
                        Fk_Branch_ID: 1,
                        Appo_WaitingOrder: 0,
                        OverBooking: false,
                        OB_Appo_ID: null,
                        Branch_Name: 'Al-LULU Medical Complex',
                        Branch_Name_Ar: 'Al-LULU Medical Complex',
                        Is_TeleMed: false,
                    },
                    {
                        Appo_ID: 15563,
                        Appo_Start: '2024-01-09T08:00:00',
                        Appo_End: '2024-01-09T08:40:00',
                        patient_code: '282143',
                        Patient_Name_Ar: 'السيد عبد المعطي إبراهيم محمد',
                        Patient_Name_En: 'ELSAYED ABDELMOTI IBRAHIM MOHAMED',
                        DoctorCode: '82',
                        DoctorNameAr: 'نهال الدمرداش',
                        DoctorNameEn: 'NEHAL ALDEMERDASH',
                        ClinicDept_Code: '31',
                        ClinicDept_Name_Ar: 'PHYSIOTHERAPY',
                        ClinicDept_Name_En: 'PHYSIOTHERAPY',
                        STATUS: 1,
                        StatusName_Ar: 'جديد',
                        StatusName_En: 'New',
                        Appo_Notes: null,
                        Fk_Branch_ID: 1,
                        Appo_WaitingOrder: 0,
                        OverBooking: false,
                        OB_Appo_ID: null,
                        Branch_Name: 'Al-LULU Medical Complex',
                        Branch_Name_Ar: 'Al-LULU Medical Complex',
                        Is_TeleMed: false,
                    },
                ];
                let appointments = this.PatientVisits.filter(
                    (item) => new Date(item.Appo_End) >= currentDate
                );
                this.lastAppintment = appointments.pop();
            });
    }
    gotoAppointment() {
        this.route.navigate(['appointments']);
    }
    getAppointmentDate() {
        return this.lastAppintment?.Appo_End.split('T')[0];
    }
    getAppointmentTime() {
        return (
            this.lastAppintment?.Appo_Start.split('T')[1] +
            ' - ' +
            this.lastAppintment?.Appo_End.split('T')[1]
        );
    }
}
