import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { VisitClient } from 'src/app/Services/Visit.service';
import { getPatient } from 'src/app/Functions/Utilities';
import swal from 'sweetalert';
// declare var $: any;

@Component({
    selector: 'app-visit-details',
    templateUrl: './visit-details.component.html',
    styleUrls: ['./visit-details.component.scss'],
})
export class VisitDetailsComponent implements OnInit {
    appointment: any;
    medicineList: any[] = [];
    VisitAnalysisList: any[] = [];
    visitRay: any;
    DoctorPrescription_Url!: any;

    constructor(
        public translate: TranslateService,
        private route: Router,
        private visitClient: VisitClient
    ) {}
    ngOnInit(): void {
        this.appointment = history.state['Appointment'];

        if (!this.appointment) {
            swal({
                title: this.translate.instant('signInFirst'),
                text: this.translate.instant('GotoMainPage'),
                icon: 'error',
                timer: 1000,
                buttons: {},
            }).then(
                () => {
                    this.route.navigate(['home-one']);
                },
                (dismiss) => {
                    if (dismiss === 'timer') {
                    }
                }
            );
        }
    }
    convertDate(Visit_Date: any) {
        const Visit_Date_Split = Visit_Date.split('/');
        const day = Visit_Date_Split[0];
        const month = Visit_Date_Split[1];
        const year = Visit_Date_Split[2];
        return month + '-' + day + '-' + year;
    }
    GetVisitRays() {
        const appParam = {
            Patient_Code: getPatient()?.Patient_Code,
            Doctor_Code: this.appointment?.DoctorCode,
            Visit_Date: this.convertDate(this.appointment?.Visit_Date),
        };
        this.visitClient
            .visit_GetVisitRays(appParam)
            .subscribe((response: any) => {
                this.visitRay = response.VisitRays[0];
            });
    }

    getServiceName() {
        return this.translate.currentLang == 'en'
            ? this.visitRay?.ServiceNameEn ?? ''
            : this.visitRay?.ServiceNameAr ?? '';
    }
    GetVisitMedicine() {
        const appParam = {
            Patient_Code: getPatient()?.Patient_Code,
            Doctor_Code: this.appointment?.DoctorCode,
            Visit_Date: this.convertDate(this.appointment?.Visit_Date),
        };
        if (this.medicineList.length == 0) {
            this.visitClient
                .visit_GetVisitMedicine(appParam)
                .subscribe((response: any) => {
                    this.medicineList = response.VisitMedicine;
                });
        }
    }
    GetVisitAnalysis() {
        if (this.VisitAnalysisList.length == 0) {
            const appParam = {
                Patient_Code: getPatient()?.Patient_Code,
                Doctor_Code: this.appointment?.DoctorCode,
                Visit_Date: this.convertDate(this.appointment?.Visit_Date),
            };
            this.visitClient
                .visit_GetVisitAnalysis(appParam)
                .subscribe((response: any) => {
                    this.VisitAnalysisList = response.VisitAnalysis;
                });
        }
    }
    visit_DoctorPrescription() {
        const appParam = {
            Patient_Code: getPatient()?.Patient_Code,
            FromDate: this.convertDate(this.appointment?.Visit_Date),
            ToDate: this.convertDate(this.appointment?.Visit_Date),
            IsReturnJson: '0',
            Diag_HD_Code: this.appointment.Diag_HD_Code,
        };
        this.visitClient
            .visit_DoctorPrescription(appParam)
            .subscribe((response: any) => {
                window.open(response._Url, '_blank');
            });
    }
    visit_GetPatientVisits() {
        const appParam = {
            PatientCode: getPatient()?.Patient_Code,
            EntryDate: this.convertDate(this.appointment?.Visit_Date),
            DoctorCode: this.appointment.DoctorCode,
            IslangArabic: this.translate.currentLang == 'en' ? 0 : 1,
        };
        this.visitClient
            .visit_Report_PatientVisit(appParam)
            .subscribe((response: any) => {
                if (response._Url != '') window.open(response._Url, '_blank');
                else
                    swal({
                        title: this.translate.instant('NoData'),
                        icon: 'error',
                        timer: 2000,
                        buttons: {},
                    });
            });
    }
    getMedicineName(Medicine) {
        return this.translate.currentLang == 'en'
            ? Medicine?.ItemNameEn ?? ''
            : Medicine?.ItemNameAr ?? '';
    }
}
