import { Component, OnInit, Optional, Inject, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import {
    getPatient,
    saveUserInfoToStorage,
} from '../../../Functions/Utilities';

import { AccountClient } from 'src/app/Services/accountService.service';
import {
    API_BASE_URL
} from 'src/app/Services/accountService.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {
    ProfileForm: FormGroup;
    ImageURL: any;
    file: File = null;
    private baseUrl: string;

    constructor(
        public translate: TranslateService,
        private fb: FormBuilder,
        private accountClient: AccountClient,
        private route: Router,
        @Optional() @Inject(API_BASE_URL) baseUrl?: string
    ) {
        this.baseUrl = baseUrl;
    }

    ngOnInit(): void {
        this.createForm();

        this.accountClient
            .account_GetPatientByCode({
                Patient_Code: getPatient().Patient_Code!,
            })
            .subscribe((responsePatient: any) => {
                const patient = responsePatient.patient[0];
                this.ProfileForm.setValue({
                    Patient_Code: patient.Patient_Code ?? '',
                    Patient_FirstName_Ar: patient.Patient_Name_Ar ?? '',
                    Patient_FatherName_Ar: patient.Patient_FatherName_Ar ?? '',
                    Patient_MiddleName_Ar: patient.Patient_MiddleName_Ar ?? '',
                    Patient_LastName_Ar: patient.Patient_LastName_Ar ?? '',
                    Patient_Name_Ar: patient.Patient_Name_Ar ?? '',
                    Patient_FirstName_En: patient.Patient_FirstName_En ?? '',
                    Patient_FatherName_En: patient.Patient_FatherName_En ?? '',
                    Patient_MiddleName_En: patient.Patient_MiddleName_En ?? '',
                    Patient_LastName_En: patient.Patient_LastName_En ?? '',
                    Patient_Name_En: patient.Patient_Name_En ?? '',
                    Sex: patient.Sex ?? '',
                    Patient_BirthDate:
                        patient.Patient_BirthDate.split('T')[0] ?? '',
                    Patient_Mobile: patient.Patient_Mobile ?? '',
                    User_Name: patient.User_Name ?? '',
                    Email: patient.Email ?? '',
                    CountryCode: '',
                    ID_Number: patient.ID_Number ?? '',
                });
                this.ImageURL = this.baseUrl + '/PatientImages/' + patient.IMG;
            });
    }

    createForm() {
        this.ProfileForm = this.fb.group({
            Patient_Code: '',
            Patient_FirstName_Ar: ['', [Validators.required]],
            Patient_FatherName_Ar: '',
            Patient_MiddleName_Ar: '',
            Patient_LastName_Ar: ['', [Validators.required]],
            Patient_Name_Ar: '',
            Patient_FirstName_En: '',
            Patient_FatherName_En: '',
            Patient_MiddleName_En: '',
            Patient_LastName_En: '',
            Patient_Name_En: '',
            Sex: ['', [Validators.required]],
            Patient_BirthDate: ['', [Validators.required]],
            Patient_Mobile: [
                '',
                [Validators.required, Validators.pattern('^[0-9]{6,50}$')],
            ],
            User_Name: ['', [Validators.required]],

            Email: ['', [Validators.required, Validators.email]],
            CountryCode: '',
            ID_Number: [
                '',
                [Validators.required, Validators.pattern('^[0-9]{6,50}$')],
            ],
        });
    }

    onChange(event) {
        this.file = event.target.files[0];
        let reader = new FileReader();
        reader.readAsDataURL(this.file);
        reader.onload = () => {
            this.ImageURL = reader.result;
        };
    }

    CheckConfirmedValidator(controlName: string, matchingControlName: string) {
        return (formGroup: FormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];
            if (
                matchingControl.errors &&
                !matchingControl.errors.confirmedValidator
            ) {
                return;
            }
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ confirmedValidator: true });
            } else {
                matchingControl.setErrors(null);
            }
        };
    }

    onSubmit() {
        if (this.ProfileForm.valid) {
            this.ProfileForm.value.Patient_Name_Ar =
                this.ProfileForm.value.Patient_FirstName_Ar;

            this.accountClient
                .account_EditPatient(this.ProfileForm.value)
                .subscribe((responsePatient: any) => {
                    if (responsePatient.Success) {
                        if (this.file != null) {
                            const formData = new FormData();
                            formData.append(this.file.name, this.file);
                            this.accountClient
                                .account_UpdatePatientIMG(
                                    formData,
                                    responsePatient.Result?.patient[0]
                                        .Patient_Code
                                )
                                .subscribe((response: any) => {
                                    saveUserInfoToStorage(
                                        responsePatient.Result,
                                        response.Image,
                                        false
                                    );

                                    swal({
                                        title: this.translate.instant(
                                            'loginSuccessfully'
                                        ),
                                        text: this.translate.instant(
                                            'GotoMainPage'
                                        ),
                                        icon: 'success',
                                        timer: 1000,
                                        buttons: {},
                                    }).then(
                                        () => {
                                            this.route.navigate(['home-one']);
                                        },
                                        (dismiss) => {
                                            if (dismiss === 'timer') {
                                            }
                                        }
                                    );
                                });
                        } else {
                            saveUserInfoToStorage(
                                responsePatient.Result,
                                responsePatient.Result.patient[0].IMG,
                                false
                            );
                            swal({
                                title: this.translate.instant(
                                    'loginSuccessfully'
                                ),
                                text: this.translate.instant('GotoMainPage'),
                                icon: 'success',
                                timer: 1000,
                                buttons: {},
                            }).then(
                                () => {
                                    this.route.navigate(['home-one']);
                                },
                                (dismiss) => {
                                    if (dismiss === 'timer') {
                                    }
                                }
                            );
                        }
                    } else {
                        swal({
                            title: this.translate.instant('loginFailed'),
                            text: this.translate.instant(
                                responsePatient.Result
                            ),
                            icon: 'error',
                            timer: 2000,
                            buttons: {},
                        });
                    }
                });
        } else {
            Object.keys(this.ProfileForm.controls).forEach((field) => {
                const control = this.ProfileForm.get(field);
                control.markAsTouched({ onlySelf: true });
            });
        }
    }

}
