<div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
        <div class="modal-header">
            <h5 class="modal-title">{{ translate.instant("searchBranch") }}</h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
            >
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-10 col-12 mb-3">
                    <div class="search-wrapper">
                        <input
                            class="form-control"
                            type="text"
                            [placeholder]="translate.instant('searchBranch')"
                            [(ngModel)]="searchControl.branch"
                            (input)="search()"
                        />
                        <i class="bx bx-search"></i>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="branchsFilter.length!=0">
                <div class="col-12 branches_list">
                    <div
                        class="branchs_wrapper p-2"
                        *ngFor="let Branch of branchsFilter"
                        (click)="selectBranch(Branch!)"
                        [ngClass]="{
                            selected:
                                Branch?.Branch_ID == BranchSelected?.Branch_ID
                        }"
                    >
                        <div class="branch_Box mb-2">
                            <div class="text">
                                <h5>{{ getBranchName(Branch!) }}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
             <div class="row">
                            <div class="col-12 mb-2 NoData_Div" *ngIf="branchsFilter.length==0">
                                <div class="card">
                                    <div class="card-body">
                                        <div class="img">
                                            <img
                                                src="../../../../../assets/img/no-data.png"
                                            />
                                        </div>
                                        <div class="txt mt-3 mb-4">
                                            <p class="mb-0">
                                                {{
                                                    translate.instant(
                                                        "NoData"
                                                    )
                                                }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        </div>
    </div>
</div>
