import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from '@angular/common/http';
import {
    Observable,
    timer,
    defer,
} from 'rxjs/';
import {
    finalize,
    take,
    takeWhile,
} from 'rxjs/operators';
import { LoaderService } from '../Services/loader.service';
import { environment } from '../../environments/environment';
import { LocalStorageEnums } from '../Enums/LocalStorageEnums.enum';

@Injectable()
export class IHttpInterceptorService implements HttpInterceptor {
    constructor(private loadingService: LoaderService) {}
    alive = true;
    /**
     * Intercept all HTTP request to add JWT token to Headers
     * @param {HttpRequest<any>} request
     * @param {HttpHandler} next
     * @returns {Observable<HttpEvent<any>>}
     * @memberof TokenInterceptor
     */
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        request = request.clone({
            setHeaders: {
                Authorization:
                    `Basic ` +
                    btoa(`${environment.Username}:${environment.Password}`),
                ProviderId: `${environment.ProviderId}`,
                BranchId: `${this.getBranchId()}`,
                org: `${environment.org}`,
                SessionExpireDate: `${this.getSessionExpireDate()}`,
                sessionDuration: `${this.getsessionDuration()}`,
            },
        });
        this.alive = true;
        return next.handle(request).pipe(
            prepare(() =>
                timer(500)
                    .pipe(
                        takeWhile(() => this.alive),
                        take(1)
                    )
                    .subscribe(() => {
                        this.loadingService.setLoading(true);
                    })
            ),
            finalize(() => {
                this.loadingService.setLoading(false);
                this.alive = false;
            })
        );
    }
    getSessionExpireDate(): string {
        return localStorage.getItem(LocalStorageEnums.SessionExpireDate);
    }

    getsessionDuration(): string {
        return localStorage.getItem(LocalStorageEnums.sessionDuration);
    }
    getBranchId(): string {
        if (localStorage.getItem(LocalStorageEnums.BranchId) ==undefined||localStorage.getItem(LocalStorageEnums.BranchId) == '')
            return `${environment.BranchId}`;
        else
            return localStorage.getItem(LocalStorageEnums.BranchId);
    }
}
export const prepare = <T>(callback: () => void) => {
  return (source: Observable<T>): Observable<T> =>
    defer(() => {
      callback();
      return source;
    });
};
   


