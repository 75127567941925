<div class="gallery-area pt-100 pb-70 mt-lg-3 mt-5">
    <div class="container">
        <div class="row" *ngIf="Packages.length!=0">
            <div class="col-12">
                <div class="Pack_wrapper">
                    <div class="Pack_Box" *ngFor="let Package of Packages" >
                        <div class="right">
                            <p class="price mb-0">
                               <span>{{Package?.PackageNetAmnt}}</span> {{translate.instant("SaudiRiyal")}}
                            </p>
                            <p class="aftersale mb-0">
                                {{translate.instant("AfterSale")}}
                            </p>

                        </div>
                        <div class="left">
                            <h4 class="name mb-3">
                                <strong>{{Package?.Package_Name}} </strong>
                            </h4>
                            <p>
                               <strong>{{translate.instant("branch")}}:</strong>
                              {{getBranch_Name(Package)}}
                            </p>
                        </div>

                    </div>


                </div>
            </div>
            </div>
           <div class="row">
            <div class="col-12 mb-2 NoData_Div" *ngIf="Packages.length==0">
                <div class="card">
                    <div class="card-body">
                        <div class="img">
                            <img
                                src="../../../../../assets/img/no-data.png"
                            />
                        </div>
                        <div class="txt mt-3 mb-4">
                            <p class="mb-0">
                                {{
                                    translate.instant(
                                        "NoData"
                                    )
                                }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
           </div>
        </div>

    </div>
