import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountClient } from '../../../Services/accountService.service';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { saveUserInfoToStorage } from '../../../Functions/Utilities';
declare var $: any;

@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit {
    loginForm: FormGroup;
    forgetEmail: FormGroup;
    VerficationCodeForm: FormGroup;
    resultForgetEmail: any;
    @ViewChild('VerficationCodeModel') VerficationCodeModel;

    constructor(
        private fb: FormBuilder,
        private accountClient: AccountClient,
        private route: Router,
        public translate: TranslateService
    ) {}

    ngOnInit(): void {
        this.createForm();
    }
    createForm() {
        this.loginForm = this.fb.group({
            Email: ['', [Validators.required, Validators.email]],
            Password: ['', Validators.required],
        });
        this.forgetEmail = this.fb.group({
            Email: ['', [Validators.required, Validators.email]],
        });
        this.VerficationCodeForm = this.fb.group({
            VerficationCode: ['', [Validators.required]],
        });
    }
    onSubmitVerficationCodeForm() {
        const VerficationCode = this.VerficationCodeForm.value?.VerficationCode;

        if (VerficationCode == this.resultForgetEmail.Verfication_code) {
            saveUserInfoToStorage(
                this.resultForgetEmail,
                this.resultForgetEmail.patient[0].IMG
            );
            $('#VerficationCodeModel').modal('hide');
            swal({
                title: this.translate.instant('loginSuccessfully'),
                text: this.translate.instant('GotoMainPage'),
                icon: 'success',
                timer: 1000,
                buttons: {},
            }).then(
                () => {
                    location.href = '/home-one';
                    //  this.route.navigate(['home-one']);
                },
                (dismiss) => {
                    if (dismiss === 'timer') {
                    }
                }
            );
        } else {
            swal({
                title: this.translate.instant('loginFailed'),
                text: this.translate.instant('VerficationCodeError'),
                icon: 'error',
                timer: 2000,
                buttons: {},
            });
        }
    }
    onSubmitForgetPassword() {
        const Email = this.forgetEmail.value?.Email;
        this.accountClient
            .account_SendCodeViaEmailSM(Email)
            .subscribe((response: any) => {
                if (response.Success) {
                    this.resultForgetEmail = response.Result;
                    $('#VerficationCodeModel').modal('show');
                } else {
                    swal({
                        title: this.translate.instant('loginFailed'),
                        text: this.translate.instant(response.Result),
                        icon: 'error',
                        timer: 2000,
                        buttons: {},
                    });
                }
            });
    }
    onSubmit() {
        if (this.loginForm.valid) {
            this.accountClient
                .account_Login(this.loginForm.value)
                .subscribe((response: any) => {
                    if (response.Success) {
                        saveUserInfoToStorage(
                            response.Result,
                            response.Result.patient[0].IMG
                        );
                        swal({
                            title: this.translate.instant('loginSuccessfully'),
                            text: this.translate.instant('GotoMainPage'),
                            icon: 'success',
                            timer: 1000,
                            buttons: {},
                        }).then(
                            () => {
                                location.href = '/home-one';

                                // this.route.navigate(['home-one']);
                            },
                            (dismiss) => {
                                if (dismiss === 'timer') {
                                }
                            }
                        );
                    } else {
                        swal({
                            title: this.translate.instant('loginFailed'),
                            text: this.translate.instant(response.Result),
                            icon: 'error',
                            timer: 2000,
                            buttons: {},
                        });
                    }
                });
        } else {
            Object.keys(this.loginForm.controls).forEach((field) => {
                const control = this.loginForm.get(field);
                control.markAsTouched({ onlySelf: true });
            });
        }
    }
}

// "Email":"saraa_salem765@yahoo.con",
//     "Password":"123"
