<div class="gallery-area pt-100 pb-70 mt-lg-3 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <ul class="nav nav-pills mb-3 px-0" id="tab" role="tablist">
                    <li class="nav-item">
                        <a
                            class="nav-link active"
                            id="PrevApps-tab"
                            data-toggle="pill"
                            href="#PrevApps"
                            role="tab"
                            aria-controls="PrevApps"
                            aria-selected="true"
                            (click)="getPatientPreviousAppointment()"
                            >{{ translate.instant("PrevApps") }}</a
                        >
                    </li>
                    <li class="nav-item">
                        <a
                            class="nav-link"
                            id="UpcomingApps-tab"
                            data-toggle="pill"
                            href="#UpcomingApps"
                            role="tab"
                            aria-controls="UpcomingApps"
                            aria-selected="false"
                            (click)="getPatientNextAppointment()"
                            >{{ translate.instant("UpcomingApps") }}</a
                        >
                    </li>
                </ul>
                <div class="tab-content" id="tabContent">
                    <div
                        class="tab-pane fade show active"
                        id="PrevApps"
                        role="tabpanel"
                        aria-labelledby="PrevApps-tab"
                    >
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="row">
                                        <div class="col-12" *ngIf="previousPatientVisits.length!=0">
                                            <div class="Apps_wrapper">
                                                <div
                                                    class="Apps_Box"
                                                    *ngFor="
                                                        let appointment of previousPatientVisits
                                                    "
                                                >
                                                    <div class="right">
                                                        <p class="date mb-0">
                                                            {{
                                                                appointment?.Visit_Date
                                                            }}
                                                        </p>
                                                    </div>
                                                    <div class="middle">
                                                        <p>
                                                            <strong
                                                                >{{
                                                                    translate.instant(
                                                                        "clinic"
                                                                    )
                                                                }}:</strong
                                                            >
                                                            {{
                                                                appointment?.Clinic_Name
                                                            }}
                                                        </p>
                                                        <p>
                                                            <strong
                                                                >{{
                                                                    translate.instant(
                                                                        "doctor"
                                                                    )
                                                                }}:</strong
                                                            >
                                                            {{
                                                                appointment?.Doctor_Name
                                                            }}
                                                        </p>
                                                        <p>
                                                            <strong
                                                                >{{
                                                                    translate.instant(
                                                                        "branch"
                                                                    )
                                                                }}:</strong
                                                            >
                                                            {{
                                                                appointment?.Branch_Name
                                                            }}
                                                        </p>
                                                    </div>
                                                    <div class="left">
                                                        <button
                                                            class="default-btn-one-outline px-1"
                                                            type="button"
                                                            (click)="
                                                                goToVisitDetails(
                                                                    appointment
                                                                )
                                                            "
                                                        >
                                                            {{
                                                                translate.instant(
                                                                    "VisitDetails"
                                                                )
                                                            }}
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-2 NoData_Div" *ngIf="previousPatientVisits.length==0">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="img">
                                                        <img
                                                            src="../../../../../assets/img/no-data.png"
                                                        />
                                                    </div>
                                                    <div class="txt mt-3 mb-4">
                                                        <p class="mb-0">
                                                            {{
                                                                translate.instant(
                                                                    "NoData"
                                                                )
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="tab-pane fade"
                        id="UpcomingApps"
                        role="tabpanel"
                        aria-labelledby="UpcomingApps-tab"
                    >
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="row">
                                        <div class="col-12" *ngIf="nextPatientVisits.length!=0">
                                            <div
                                                class="Apps_wrapper UpcomingApps"
                                            >
                                                <div
                                                    class="Apps_Box"
                                                    *ngFor="
                                                        let appointment of nextPatientVisits
                                                    "
                                                >
                                                    <div class="right">
                                                        <p class="date mb-0">
                                                            {{
                                                                getAppo_StartDate(
                                                                    appointment
                                                                )
                                                            }}
                                                        </p>
                                                        <hr />
                                                        <p class="time mb-0">
                                                            {{
                                                                getAppo_Time(
                                                                    appointment
                                                                )
                                                            }}
                                                        </p>
                                                    </div>
                                                    <div class="middle">
                                                        <p>
                                                            <strong
                                                                >{{
                                                                    translate.instant(
                                                                        "clinic"
                                                                    )
                                                                }}:</strong
                                                            >
                                                            {{
                                                                getClinicDept_Name(
                                                                    appointment
                                                                )
                                                            }}
                                                        </p>
                                                        <p>
                                                            <strong
                                                                >{{
                                                                    translate.instant(
                                                                        "doctor"
                                                                    )
                                                                }}:</strong
                                                            >
                                                            {{
                                                                getDoctor_Name(
                                                                    appointment
                                                                )
                                                            }}
                                                        </p>
                                                        <p>
                                                            <strong
                                                                >{{
                                                                    translate.instant(
                                                                        "branch"
                                                                    )
                                                                }}:</strong
                                                            >
                                                            {{
                                                                getBranch_Name(
                                                                    appointment
                                                                )
                                                            }}
                                                        </p>
                                                    </div>
                                                    <div class="left">
                                                        <button
                                                            title="{{
                                                                translate.instant(
                                                                    'Delete'
                                                                )
                                                            }}"
                                                            (click)="
                                                                cancelAppointment(
                                                                    appointment
                                                                )
                                                            "
                                                        >
                                                            <i
                                                                class="fa fa-times"
                                                            ></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 mb-2 NoData_Div" *ngIf="nextPatientVisits.length==0">
                                            <div class="card">
                                                <div class="card-body">
                                                    <div class="img">
                                                        <img
                                                            src="../../../../../assets/img/no-data.png"
                                                        />
                                                    </div>
                                                    <div class="txt mt-3 mb-4">
                                                        <p class="mb-0">
                                                            {{
                                                                translate.instant(
                                                                    "NoData"
                                                                )
                                                            }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
