import { Component, OnInit , NgModule, Input, ViewEncapsulation} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageEnums } from 'src/app/Enums/LocalStorageEnums.enum';
import { AccountClient } from 'src/app/Services/accountService.service';
import { saveBranchId } from '../../../Functions/Utilities';
declare var $: any;

@Component({
    selector: 'app-branches',
    templateUrl: './branches.component.html',
    styleUrls: ['./branches.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class BranchesComponent implements OnInit {
    rtlEnabled: boolean;
    language: any;
    searchControl: any = { branch: '' };
    branchsFilter: any[] = [];
    BranchSelected: any = {
        Branch_ID: '',
    };
    Branchs: any[] = [];
    constructor(
        public translate: TranslateService,
        accountClient: AccountClient
    ) {
        accountClient.account_GetBranchs({}).subscribe((response) => {
            this.Branchs = response.Branchs;
            this.branchsFilter = response.Branchs;
        });
        translate.addLangs(['en', 'ar']);
        this.language =
            localStorage.getItem(LocalStorageEnums.NitcotekTalebcomLanguage) ??
            '';
        if (this.language == '') this.language = 'ar';
        translate.setDefaultLang(this.language);
        translate.use(this.language);
        document.dir = this.language == 'ar' ? 'rtl' : 'ltr';
        if (document.dir == 'rtl') {
            this.rtlEnabled = true;
        } else {
            this.rtlEnabled = false;
        }
    }
    selectBranch(Branch: any) {
        this.BranchSelected.Branch_ID = Branch.Branch_ID;
        saveBranchId(Branch.Branch_ID);
        $('#BranchModel').modal('hide');
        location.href = '/home-one';
    }
    getBranchName(branch: any) {
        return this.translate.currentLang == 'en'
            ? branch?.Branch_Name ?? ''
            : branch?.branch_name_ar ?? '';
    }
    search() {
        this.branchsFilter = this.Branchs.filter((branch) => {
            if (this.translate.currentLang == 'en')
                return branch.Branch_Name.toLowerCase().includes(
                    this.searchControl.branch?.trim().toLowerCase()
                );
            else
                return branch.branch_name_ar
                    .toLowerCase()
                    .includes(this.searchControl.branch?.trim().toLowerCase());
        });
    }

    ngOnInit() {}
}
