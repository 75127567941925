import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationExtras, Router } from '@angular/router';
import { OfferAndPackagesClient } from '../../../Services/OfferAndPackages.service';

@Component({
    selector: 'app-packages',
    templateUrl: './packages.component.html',
    styleUrls: ['./packages.component.scss'],
})
export class PackagesComponent implements OnInit {
    Packages: any[] = [];
    constructor(
        public translate: TranslateService,
        private offerAndPackagesClient: OfferAndPackagesClient
    ) {
        this.GetPatientVaccination();
    }
    GetPatientVaccination() {
        const appParam = {
            CurrentPage: 0,
            PageSize: null,
            DateFrom: '',
            DateTo: '',
        };
        this.offerAndPackagesClient
            .offerAndPackages_GetPackagesByDate(appParam)
            .subscribe((response: any) => {
                this.Packages = response.Packages;
            });
    }

    getBranch_Name(Package: any) {
        return this.translate.currentLang == 'en'
            ? Package?.Branch_Name ?? ''
            : Package?.Branch_Name_Ar ?? '';
    }
    ngOnInit(): void {}
}
