import {Injectable, Injector} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http'
import {Observable} from 'rxjs';
import { HttpError } from '../class/HttpError'
import { tap } from 'rxjs/operators';
import swal from 'sweetalert';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    // Regular dep. injection doesn't work in HttpInterceptor due to a framework issue (as of angular@5.2.9),
    // use Injector directly (don't forget to add @Injectable() decorator to class).
    constructor(
        private _injector: Injector,
        private route: Router,
        public translate: TranslateService
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                (event) => {},
                (exception) => {
                    if (exception instanceof HttpErrorResponse) {
                        switch (exception.status) {
                            case HttpError.BadRequest:
                                swal({
                                    title: '(BadRequest) يرجى طلب الدعم الفني لعدم الاتصال بقاعدة البيانات ',
                                    icon: 'error',
                                    timer: 4000,
                                    buttons: {},
                                });
                                break;

                            case HttpError.Unauthorized:
                                swal({
                                    title: this.translate.instant(
                                        'signInFirst'
                                    ),
                                    text: this.translate.instant(
                                        'GotoMainPage'
                                    ),
                                    icon: 'success',
                                    timer: 1000,
                                    buttons: {},
                                }).then(
                                    () => {
                                        this.route.navigate(['home-one']);
                                    },
                                    (dismiss) => {
                                        if (dismiss === 'timer') {
                                        }
                                    }
                                );
                                //to sign -in
                                break;

                            case HttpError.NotFound:
                                //show error toast message
                                swal({
                                    title: '(Forbidden) يرجى طلب الدعم الفني لعدم الاتصال بقاعدة البيانات ',
                                    icon: 'error',
                                    timer: 4000,
                                    buttons: {},
                                });
                                // const _toaster = this._injector.get(Toaster),
                                //   _router = this._injector.get(Router);
                                // _toaster.show({
                                //   message: exception.error && exception.error.message ? exception.error.message :
                                //     exception.statusText,
                                //   typeId: 'error',
                                //   isDismissable: true
                                // });
                                // _router.navigate(['']);
                                break;

                            case HttpError.TimeOut:
                                // Handled in AnalyticsExceptionHandler
                                swal({
                                    title: '(AnalyticsExceptionHandler) يرجى طلب الدعم الفني لعدم الاتصال بقاعدة البيانات ',
                                    icon: 'error',
                                    timer: 4000,
                                    buttons: {},
                                });
                                break;

                            case HttpError.Forbidden:
                                swal({
                                    title: '(Forbidden) يرجى طلب الدعم الفني لعدم الاتصال بقاعدة البيانات ',
                                    icon: 'error',
                                    timer: 4000,
                                    buttons: {},
                                });
                                break;

                            case HttpError.InternalServerError:
                                swal({
                                    title: 'يرجى طلب الدعم الفني لعدم الاتصال بقاعدة البيانات ',
                                    icon: 'error',
                                    timer: 4000,
                                    buttons: {},
                                });

                                break;
                        }
                    }
                }
            )
        );
    }
}
