import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationExtras, Router } from '@angular/router';
import { WaClient } from 'src/app/Services/Wa.service';
import { getPatient } from 'src/app/Functions/Utilities';
@Component({
    selector: 'app-vaccinations',
    templateUrl: './vaccinations.component.html',
    styleUrls: ['./vaccinations.component.scss'],
})
export class VaccinationsComponent implements OnInit {
    PatientVaccinationList: any[] = [];
    constructor(
        public translate: TranslateService,
        private route: Router,
        private waClient: WaClient
    ) {}
    ngOnInit(): void {
    this.GetPatientVaccination();
    }
    getVacc_Name(PatientVaccination:any) {
        return this.translate.currentLang == 'en'
            ? PatientVaccination?.Vacc_Name_En ?? ''
            : PatientVaccination?.Vacc_Name_Ar ?? '';
    }
    GetPatientVaccination() {
        const appParam = { patientCode: getPatient()?.Patient_Code };
        this.waClient
            .wa_GetPatientVaccination(appParam)
            .subscribe((response: any) => {
                this.PatientVaccinationList = response.PatientVaccinations;
            });
    }
}
