<!-- Hero Slider -->
<div class="hero-slider owl-carousel owl-theme mb-5">
    <div class="hero-slider-item item-bg1">
        <!-- <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-content">
                        <span>Since 1992</span>
                        <h1>Transport & Logistic Services</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <a routerLink="/contact" class="default-btn-one mr-3">Contact Us</a>
                        <a routerLink="/" class="default-btn-two">Get A Quote</a>
                    </div>
                </div>
            </div>
        </div> -->
    </div>

    <div class="hero-slider-item item-bg2">

    </div>

    <div class="hero-slider-item item-bg3">

    </div>
</div>
<!-- End Hero Slider -->

<!-- Contact Area -->
<div class="container mb-5"  *ngIf="!sessionIsExpired()">
    <div class="contact-area " *ngIf="lastAppintment">
        <div class="contact-content">
            <div class="row justify-content-center">
                <div class="col-md-4 col-sm-12 mb-3 mb-md-0 text-center app-title">
                    <h4 class=" mb-0"> {{translate.instant('NextAppointment')}} </h4>
                </div>
                <div class="col-md-4 col-6 text-center app-date">
                    <h4 class=" mb-0"> {{getAppointmentDate()}} </h4>
                </div>
                <div class="col-md-4 col-6 text-center app-time text-left">
                    <button class=" default-btn-one-outline " (click)="gotoAppointment()">
                        <b> {{getAppointmentTime()}}</b>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="contact-area mt-4" *ngIf="!lastAppintment">
        <div class="contact-content">
            <div class="row justify-content-center">
                <div class="col-md-6 mb-3 mb-md-0 app-title">
                    <h4 class=" mb-0 text-right"> {{translate.instant('noAppointment')}} </h4>
                </div>

                <div class="col-md-6 app-time text-left">
                    <button class=" default-btn-one " (click)="gotoAppointment()">
                       {{translate.instant('bookNow')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Contact Area -->


<!-- clinic/appointment Area -->
<div class="container mb-5">

            <div class="row justify-content-center">
<div  class="col-md-6 d-flex mb-3">
    <div  class="blog grid-blog">
        <div  class="blog-image">
            <a routerLink="/clinics">
                <img  src="../../../../assets/img/clinics.png" alt="clinics Image"
                class="img-fluid">
            </a>
            </div>
                <div  class="blog-content d-md-flex justify-content-between align-items-center">
                    <h3  class="blog-title">
                        <a  routerLink="/clinics"> {{translate.instant('clinics')}}</a>
                    </h3>
                    <a  class="default-btn-one-outline viewlink" routerLink="/clinics">المزيد
                        <i  class="fa fa-arrow-left"></i>
</a>
</div>
</div>
</div>
<div  class="col-md-6 d-flex mb-3" *ngIf="!sessionIsExpired()">
    <div  class="blog grid-blog">
        <div  class="blog-image">
            <a href="">
                <img  src="../../../../assets/img/appointments.png" alt="appointments Image"
                class="img-fluid">
            </a>
            </div>
                <div  class="blog-content d-md-flex justify-content-between align-items-center">
                    <h3  class="blog-title">
                        <a  href=""> {{translate.instant('Appointments')}}</a>
                    </h3>
                    <a  class="default-btn-one-outline viewlink" routerLink="/appointments">المزيد
    <i  class="fa fa-arrow-left"></i>
</a>
</div>
</div>
</div>
</div>
</div>
