<!-- <ng-template *ngIf="true"> -->
<!-- *ngIf="(showWarningLogin ||(timerOutput.length>0 && sessionDuration >0)) && IsSessionVisable" -->
<!-- <h5>
      <i class="fa fa-clock text-danger timeout_icon"></i>
      {{ translate.instant("Session_Timeout_Msg1") }}
    </h5>
    <p class="countdown">{{counter}} {{ translate.instant("Sec") }}</p>
    <h5>{{ translate.instant("Session_Timeout_Msg2") }}</h5>

    </ng-template> -->
<div
    id="sessionDuration"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="VerficationCodeModelLabel"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <p class="msg">
                {{ translate.instant("Session_Timeout_Msg1") }}
            </p>
            <p class="countdown py-3">
                {{ counter }} {{ translate.instant("Sec") }}
            </p>
            <p class="msg">
                {{ translate.instant("Session_Timeout_Msg2") }}
            </p>
            <button class="btn btn-theme1-outline mt-4" (click)="ShowCounterPreview()">
                {{translate.instant('Ignore')}}
                <i class="fa fa-chevron-down fa-fade"></i>
            </button>
        </div>
    </div>
</div>

<div class="sessionDuration_preview" id="sessionDuration_preview"
[ngClass]="{'': addClass, 'shown': !addClass}">
        <p >{{counter}} {{ translate.instant("Sec") }}</p>
        <button class="btn btn-sm btn-theme2" routerLink="/sign-in">
            <i class="sign-in-alt"></i>
            دخول
            </button>
        </div>
