<div class="gallery-area pt-100 pb-70 mt-lg-3 mt-5">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="page-title">
                    <h4>
                        {{ translate.instant("VisitDetails") }}
                    </h4>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="VD_preview-wrapper mb-5">
                    <div class="card">
                        <p class="mb-0">
                            <strong>{{ translate.instant("clinic") }}:</strong>
                            {{ appointment?.Clinic_Name }}
                        </p>
                        <p class="mb-0">
                            <strong>{{ translate.instant("doctor") }}:</strong>
                            {{ appointment?.Doctor_Name }}
                        </p>
                        <p class="mb-0">
                            <strong>{{ translate.instant("branch") }}:</strong>
                            {{ appointment?.Branch_Name }}
                        </p>
                        <p class="mb-0">
                            <strong>{{ translate.instant("Appdate") }}:</strong>
                            {{ appointment?.Visit_Date }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div id="accordion" class="mb-5">
                    <div class="row">
                        <div class="col-lg-6 mb-4">
                            <div class="card">
                                <div class="card-header" id="Medicines">
                                    <button
                                        #collapseMedicineButton
                                        class="btn btn-link"
                                        data-toggle="collapse"
                                        data-target="#collapseMedicines"
                                        aria-controls="collapseMedicines"
                                        aria-expanded="true"
                                        (click)="GetVisitMedicine()"
                                    >
                                        <h5 class="mb-0">
                                            {{ translate.instant("Medicines") }}
                                        </h5>
                                        <i class="fa fa-chevron-down"></i>
                                    </button>
                                </div>
                                <div
                                    id="collapseMedicines"
                                    class="collapse"
                                    aria-labelledby="Medicines"
                                    data-parent="#accordion"
                                    #collapseMedicinePanel
                                >
                                    <div
                                        class="card-body"
                                        *ngIf="medicineList.length > 0"
                                    >
                                        <table
                                            class="table-borderless medicine-table w-100"
                                        >
                                            <tbody>
                                                <tr
                                                    *ngFor="
                                                        let medicine of medicineList
                                                    "
                                                >
                                                    <td>
                                                        <h5 class="med-name">
                                                            {{
                                                                getMedicineName(
                                                                    medicine
                                                                )
                                                            }}
                                                        </h5>
                                                        <p
                                                            class="med-desc mb-0"
                                                        >
                                                            {{
                                                                medicine?.Prescription
                                                            }}
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div
                                        class="col-12 mb-2 NoData_Div"
                                        *ngIf="medicineList.length == 0"
                                    >
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="img">
                                                    <img
                                                        src="../../../../../assets/img/no-data.png"
                                                    />
                                                </div>
                                                <div class="txt mt-3 mb-4">
                                                    <p class="mb-0">
                                                        {{
                                                            translate.instant(
                                                                "NoData"
                                                            )
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="card">
                                <div class="card-header" id="XRay">
                                    <button
                                        class="btn btn-link"
                                        data-toggle="collapse"
                                        data-target="#collapseXRay"
                                        aria-expanded="true"
                                        aria-controls="collapseXRay"
                                        (click)="GetVisitRays()"
                                    >
                                        <h5 class="mb-0">
                                            {{ translate.instant("X-ray") }}
                                        </h5>
                                        <i class="fa fa-chevron-down"></i>
                                    </button>
                                </div>
                                <div
                                    id="collapseXRay"
                                    class="collapse"
                                    aria-labelledby="XRay"
                                    data-parent="#accordion"
                                >
                                     <div class="card-body">
                                        <h5 class="text-theme1">
                                            {{ translate.instant("XrayName") }}:
                                            <span>{{ getServiceName() }}</span>
                                        </h5>
                                        <h5 class="text-theme1">
                                            {{translate.instant("result") }}
                                            <span>
                                                <div
                                            [innerHTML]="visitRay?.Rad_Result"
                                        ></div>
                                                </span>
                                        </h5>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 mb-4">
                            <div class="card">
                                <div class="card-header" id="Analysis">
                                    <button
                                        #collapseAnalysisButton
                                        class="btn btn-link"
                                        data-toggle="collapse"
                                        data-target="#collapseAnalysis"
                                        aria-expanded="true"
                                        aria-controls="collapseMAnalysis"
                                        (click)="GetVisitAnalysis()"
                                    >
                                        <h5 class="mb-0">
                                            {{ translate.instant("Analysis") }}
                                        </h5>
                                        <i class="fa fa-chevron-down"></i>
                                    </button>
                                </div>
                                <div
                                    #collapseAnalysisPanel
                                    id="collapseAnalysis"
                                    class="collapse"
                                    aria-labelledby="Analysis"
                                    data-parent="#accordion"
                                >
                                    <div
                                        class="card-body"
                                        *ngIf="VisitAnalysisList.length > 0"
                                    >
                                        <table
                                            class="table-borderless analysis-table w-100"
                                        >
                                            <thead>
                                                <th>
                                                    {{
                                                        translate.instant(
                                                            "name"
                                                        )
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                        translate.instant(
                                                            "result"
                                                        )
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                        translate.instant(
                                                            "min_result"
                                                        )
                                                    }}
                                                </th>
                                                <th>
                                                    {{
                                                        translate.instant(
                                                            "max_result"
                                                        )
                                                    }}
                                                </th>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="
                                                        let VisitAnalysis of VisitAnalysisList
                                                    "
                                                >
                                                    <td>
                                                        <p
                                                            class="ana-name mb-0"
                                                        >
                                                            {{
                                                                VisitAnalysis?.Lab_TestName
                                                            }}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            class="ana-result mb-0"
                                                        >
                                                            {{
                                                                VisitAnalysis?.Lab_TestResult1
                                                            }}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            class="ana-min_result mb-0"
                                                        >
                                                            {{
                                                                VisitAnalysis?.Lab_TestMinRange
                                                            }}
                                                        </p>
                                                    </td>
                                                    <td>
                                                        <p
                                                            class="ana-max_result mb-0"
                                                        >
                                                            {{
                                                                VisitAnalysis?.Lab_TestMaxRange
                                                            }}
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div
                                        class="col-12 mb-2 NoData_Div"
                                        *ngIf="VisitAnalysisList.length == 0"
                                    >
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="img">
                                                    <img
                                                        src="../../../../../assets/img/no-data.png"
                                                    />
                                                </div>
                                                <div class="txt mt-3 mb-4">
                                                    <p class="mb-0">
                                                        {{
                                                            translate.instant(
                                                                "NoData"
                                                            )
                                                        }}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-5">
            <div class="col-12">
                <div class="row VD_reports-wrapper">
                    <div class="col-lg-6 mb-lg-0 mb-4">
                        <div class="card">
                            <div class="card-header">
                                <button
                                    class="btn btn-link"
                                    (click)="visit_GetPatientVisits()"
                                >
                                    <h5 class="mb-0">
                                        {{ translate.instant("DoctorReport") }}
                                    </h5>
                                    <i class="fa fa-file-pdf"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 mb-lg-0 mb-4">
                        <div class="card">
                            <div class="card-header">
                                <button
                                    class="btn btn-link"
                                    (click)="visit_DoctorPrescription()"
                                >
                                    <h5 class="mb-0">
                                        {{
                                            translate.instant(
                                                "DoctorPrescription"
                                            )
                                        }}
                                    </h5>
                                    <i class="fa fa-file-pdf"> </i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
