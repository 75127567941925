import { Component, OnInit, Optional, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { getPatient, sessionIsExpired } from '../../../Functions/Utilities';
import { Router } from '@angular/router';
import {  API_BASE_URL, AccountClient } from 'src/app/Services/accountService.service';
import { LocalStorageEnums } from '../../../Enums/LocalStorageEnums.enum';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
    private baseUrl: string;
    lang!: string;

    constructor(
        public translate: TranslateService,
        private route: Router,
        @Optional() @Inject(API_BASE_URL) baseUrl?: string
    ) {
        this.baseUrl = baseUrl;;
        this.lang=localStorage.getItem('NitcotekTalebcomLanguage')??'ar';

    }
    ngOnInit(): void {}
    changeLanguage() {
    //    this.language = localStorage.getItem('NitcotekTalebcomLanguage');
        this.lang = this.lang == 'ar' ? 'en' : 'ar';
        localStorage.setItem(LocalStorageEnums.NitcotekTalebcomLanguage, this.lang);
        this.translate.use(this.lang);
        location.reload();
    }
    sessionIsExpired() {
        return sessionIsExpired();
    }
    getPatientName() {
        return getPatient()?.User_Name;
    }
    getPatientIMG() {
        return this.baseUrl + '/PatientImages/' + getPatient()?.IMG;
    }
    signout() {
        localStorage.clear();
        this.route.navigate(['home-one']);
    }
}
