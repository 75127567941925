export enum LocalStorageEnums {
    User_Name = 'User_Name',
    Email = 'Email',
    ID_Number = 'ID_Number',
    Patient_Code = 'Patient_Code',
    sessionDuration = 'sessionDuration',
    SessionExpireDate = 'SessionExpireDate',
    Patient_Mobile = 'Patient_Mobile',
    Patient_Age_Year = 'Patient_Age_Year',
    Patient_Name_Ar = 'Patient_Name_Ar',
    Country = 'Country',
    Imagepath = 'Imagepath',
    NitcotekTalebcomLanguage = 'NitcotekTalebcomLanguage',
    BranchId = 'BranchId',
}