import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationExtras, Router } from '@angular/router';
import { AppintmentAPIClient } from 'src/app/Services/appointment.service';
import swal from 'sweetalert';
import { getPatient } from 'src/app/Functions/Utilities';
import { VisitClient } from '../../../../Services/Visit.service';
import { Appointment } from '../../../../Services/appointment.service';

@Component({
    selector: 'app-appointments',
    templateUrl: './appointments.component.html',
    styleUrls: ['./appointments.component.scss'],
})
export class AppointmentsComponent implements OnInit {
    nextPatientVisits: any[] = [];
    previousPatientVisits: any[] = [];

    constructor(
        public translate: TranslateService,
        private route: Router,
        private appintmentAPIClient: AppintmentAPIClient,
        private visitClient: VisitClient
    ) {
        this.getPatientPreviousAppointment();
    }
    goToVisitDetails(Appointment) {
        const navigationExtras: NavigationExtras = {
            state: { Appointment: Appointment },
        };
        this.route.navigate(['visit_details'], navigationExtras);
    }
    ngOnInit(): void {}
    getPatientPreviousAppointment() {
        const appParam = {
            Patient_Code: getPatient()?.Patient_Code,
        };
        this.visitClient
            .visit_GetPatientVisits(appParam)
            .subscribe((response: any) => {
                this.previousPatientVisits = response.PatientVisits;
            });
    }
    getPatientNextAppointment() {
        let DateNow = new Date();
        DateNow.setMonth(new Date().getMonth() + 1);
        const appParam = {
            Patient_Code: getPatient()?.Patient_Code,
            FromDate: new Date().toISOString().split('T')[0],
            ToDate: DateNow.toISOString().split('T')[0],
        };
        this.appintmentAPIClient
            .appintmentAPI_GetPatientAppointmentbydate(appParam)
            .subscribe((response: any) => {
                this.nextPatientVisits = response.PatientVisits;
            });
    }
    getAppo_StartDate(appointment) {
        return appointment.Appo_Start?.split('T')[0];
    }
    getAppo_Time(appointment) {
        const Appo_StartDate = appointment.Appo_Start;
        const Appo_StartDateSubstrings = Appo_StartDate?.split('T');
        const start_Time = Appo_StartDateSubstrings[1].trim();
        const Appo_EndDate = appointment.Appo_End;
        const Appo_EndDateSubstrings = Appo_EndDate.split('T');
        const End_Time = Appo_EndDateSubstrings[1].trim();
        return start_Time + ' - ' + End_Time;
    }
    getClinicDept_Name(appointment) {
        return this.translate.currentLang == 'en'
            ? appointment?.ClinicDept_Name_En ?? ''
            : appointment?.ClinicDept_Name_Ar ?? '';
    }
    getDoctor_Name(appointment) {
        return this.translate.currentLang == 'en'
            ? appointment?.DoctorNameEn ?? ''
            : appointment?.DoctorNameAr ?? '';
    }
    getBranch_Name(appointment) {
        return this.translate.currentLang == 'en'
            ? appointment?.Branch_Name ?? ''
            : appointment?.Branch_Name_Ar ?? '';
    }
    cancelAppointment(appointment: any) {
        const Canceledapp = {
            Appo_ID: appointment.Appo_ID,
        };
        this.appintmentAPIClient
            .appintmentAPI_CancelAppointment(Canceledapp)
            .subscribe((response: any) => {
                if (response.Success) {
                    swal({
                        title: this.translate.instant('CancelAppSuccessfully'),
                        icon: 'success',
                        timer: 2000,
                        buttons: {},
                    }).then(
                        () => {
                            this.nextPatientVisits =
                                this.nextPatientVisits.filter(
                                    (app) => app.Appo_ID != appointment.Appo_ID
                                );
                        },
                        (dismiss) => {
                            if (dismiss === 'timer') {
                            }
                        }
                    );
                }
            });
    }
}
