<!-- Page banner Area -->
<div class="page-banner bg-3">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>
                        {{ translate.instant("NewAccount") }}
                    </h2>
                    <ul>
                        <li>
                            <a routerLink="/">
                                {{ translate.instant("MainPage") }}
                            </a>
                        </li>
                        <li>
                            {{ translate.instant("NewAccount") }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Start Sign in Area -->
<div class="sign-in-area ptb-100">
    <div class="container">
        <div class="section-title">
            <img src="assets/img/logo.png" alt="logo" />
            <p>
                {{ translate.instant("GotoMainPage") }}
            </p>
        </div>

        <div class="sign-in-form sign-up-form">
            <form [formGroup]="SignUpForm" (submit)="onSubmit()" novalidate>
                <div class="row">
                    <div class="col-12">
                        <input type="file" style="display: none" #selectFile
                        (change)="onChange($event)"/>
                        <div class="img-wrapper" (click)="selectFile.click()">
                            <a >
                                <img for="selectFile"
                                src="../../../../assets/img/user-img.jpg"
                                  [src]="ImageURL"
                                  />
                                  </a>
                                                    <i class="fa fa-camera"></i>
                                                    <div class="overlay"></div>
                                                   </div>

                        <div class="form-group">
                            <img *ngIf="ImageURL"  [src]="ImageURL"/>
                            <input
                                class="form-control"
                                type="file"
                                (change)="onChange($event)"/>

                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input
                                class="form-control"
                                type="text"
                                formControlName="Patient_FirstName_Ar"
                                [placeholder]="
                                    translate.instant('Patient_FirstName_Ar')+ '*'"/>
                        </div>
                        <div
                            *ngIf="
                                SignUpForm.controls['Patient_FirstName_Ar']
                                    .invalid &&
                                (SignUpForm.controls['Patient_FirstName_Ar']
                                    .dirty ||
                                    SignUpForm.controls['Patient_FirstName_Ar']
                                        .touched)
                            "
                            class="alert alert-danger"
                        >
                            <div
                                *ngIf="
                                    SignUpForm.controls['Patient_FirstName_Ar']
                                        .errors.required
                                "
                            >
                                {{
                                    translate.instant(
                                        "Patient_FirstName_Required"
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input
                                type="text"
                                class="form-control"
                                formControlName="Patient_LastName_Ar"
                                [placeholder]="
                                    translate.instant('Patient_LastName') + '*'"/>
                        </div>
                        <div
                            *ngIf="
                                SignUpForm.controls['Patient_LastName_Ar']
                                    .invalid &&
                                (SignUpForm.controls['Patient_LastName_Ar']
                                    .dirty ||
                                    SignUpForm.controls['Patient_LastName_Ar']
                                        .touched)
                            "
                            class="alert alert-danger"
                        >
                            <div
                                *ngIf="
                                    SignUpForm.controls['Patient_LastName_Ar']
                                        .errors.required
                                "
                            >
                                {{
                                    translate.instant(
                                        "Patient_LastName_Required"
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input
                                type="number"
                                class="form-control"
                                formControlName="ID_Number"
                                [placeholder]="translate.instant('ID_Number') + '*'"
                            />
                        </div>
                        <div
                            *ngIf="
                                SignUpForm.controls['ID_Number'].invalid &&
                                (SignUpForm.controls['ID_Number'].dirty ||
                                    SignUpForm.controls['ID_Number'].touched)
                            "
                            class="alert alert-danger"
                        >
                            <div
                                *ngIf="
                                    SignUpForm.controls['ID_Number'].errors
                                        .required
                                "
                            >
                                {{ translate.instant("ID_Number_required") }}
                            </div>
                            <div
                                *ngIf="
                                    SignUpForm.controls['ID_Number'].errors
                                        .pattern
                                "
                            >
                                {{
                                    translate.instant("ID_NumberInvalidLength")
                                }}
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <ng-select
                                [items]="SexList"
                                formControlName="Sex"
                                [searchable]="true"
                                bindLabel="Type"
                                bindValue="Type"
                                [placeholder]="translate.instant('Sex') + '*'"
                            >
                            </ng-select>
                        </div>
                        <div
                            *ngIf="
                                SignUpForm.controls['Sex'].invalid &&
                                (SignUpForm.controls['Sex'].dirty ||
                                    SignUpForm.controls['Sex'].touched)
                            "
                            class="alert alert-danger"
                        >
                            <div
                                *ngIf="
                                    SignUpForm.controls['Sex'].errors.required
                                "
                            >
                                {{ translate.instant("SexRequired") }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input
                                type="text"
                                class="form-control"
                                formControlName="User_Name"
                                [placeholder]="translate.instant('User_Name') + '*'"
                            />
                        </div>
                        <div
                            *ngIf="
                                SignUpForm.controls['User_Name'].invalid &&
                                (SignUpForm.controls['User_Name'].dirty ||
                                    SignUpForm.controls['User_Name'].touched)
                            "
                            class="alert alert-danger"
                        >
                            <div
                                *ngIf="
                                    SignUpForm.controls['User_Name'].errors
                                        .required
                                "
                            >
                                {{ translate.instant("User_Name_required") }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input
                                type="date"
                                class="form-control"
                                formControlName="Patient_BirthDate"
                                [placeholder]="
                                    translate.instant('Patient_BirthDate') + '*'"/>
                        </div>
                        <div
                            *ngIf="
                                SignUpForm.controls['Patient_BirthDate']
                                    .invalid &&
                                (SignUpForm.controls['Patient_BirthDate']
                                    .dirty ||
                                    SignUpForm.controls['Patient_BirthDate']
                                        .touched)
                            "
                            class="alert alert-danger"
                        >
                            <div
                                *ngIf="
                                    SignUpForm.controls['Patient_BirthDate']
                                        .errors.required
                                "
                            >
                                {{
                                    translate.instant(
                                        "Patient_BirthDate_required"
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input
                                type="number"
                                class="form-control"
                                formControlName="Patient_Mobile"
                                [placeholder]="
                                    translate.instant('Patient_Mobile') + '*'"
                            />
                        </div>
                        <div
                            *ngIf="
                                SignUpForm.controls['Patient_Mobile'].invalid &&
                                (SignUpForm.controls['Patient_Mobile'].dirty ||
                                    SignUpForm.controls['Patient_Mobile']
                                        .touched)
                            "
                            class="alert alert-danger"
                        >
                            <div
                                *ngIf="
                                    SignUpForm.controls['Patient_Mobile'].errors
                                        .required
                                "
                            >
                                {{
                                    translate.instant("Patient_Mobile_required")
                                }}
                            </div>
                            <div
                                *ngIf="
                                    SignUpForm.controls['Patient_Mobile'].errors
                                        .pattern
                                "
                            >
                                {{
                                    translate.instant("Patient_Mobile_required")
                                }}
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="form-group">
                            <input
                                class="form-control"
                                type="text"
                                formControlName="Email"
                                [placeholder]="translate.instant('Email') + '*'"
                            />
                        </div>
                        <div
                            *ngIf="
                                SignUpForm.controls['Email'].invalid &&
                                (SignUpForm.controls['Email'].dirty ||
                                    SignUpForm.controls['Email'].touched)
                            "
                            class="alert alert-danger"
                        >
                            <div
                                *ngIf="
                                    SignUpForm.controls['Email'].errors.required
                                "
                            >
                                {{ translate.instant("EmailRequired") }}
                            </div>
                            <div
                                *ngIf="
                                    SignUpForm.controls['Email'].errors.email
                                "
                            >
                                {{ translate.instant("EmailWrongFormate") }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input
                                type="password"
                                class="form-control"
                                formControlName="Password"
                                [placeholder]="translate.instant('Password') + '*'"
                            />
                        </div>
                        <div
                            *ngIf="
                                SignUpForm.controls['Password'].invalid &&
                                (SignUpForm.controls['Password'].dirty ||
                                    SignUpForm.controls['Password'].touched)
                            "
                            class="alert alert-danger"
                        >
                            <div
                                *ngIf="
                                    SignUpForm.controls['Password'].errors
                                        .required
                                "
                            >
                                {{ translate.instant("PasswordRequired") }}
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="form-group">
                            <input
                                type="password"
                                class="form-control"
                                formControlName="ConfirmedPassword"
                                [placeholder]="
                                    translate.instant('ConfirmedPassword') + '*'"
                            />
                        </div>
                        <div
                            *ngIf="
                                SignUpForm.controls['ConfirmedPassword']
                                    .invalid &&
                                (SignUpForm.controls['ConfirmedPassword']
                                    .dirty ||
                                    SignUpForm.controls['ConfirmedPassword']
                                        .touched)
                            "
                            class="alert alert-danger"
                        >
                            <div
                                *ngIf="
                                    SignUpForm.controls['ConfirmedPassword']
                                        .errors.required
                                "
                            >
                                {{
                                    translate.instant(
                                        "ConfirmedPasswordRequired"
                                    )
                                }}
                            </div>

                            <div
                                *ngIf="
                                    SignUpForm.controls['ConfirmedPassword']
                                        .errors.confirmedValidator
                                "
                            >
                                {{
                                    translate.instant(
                                        "ConfirmedPasswordNotIdentical"
                                    )
                                }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mb-5 justify-content-end">
                    <div class="col-sm-6">
                        <p class="account-decs left">
                            {{ translate.instant("alreadyHaveAccount") }}
                            <a routerLink="/sign-in">
                                {{ translate.instant("login") }}</a
                            >
                        </p>
                    </div>
                </div>
                <div class="text-end">
                    <button
                        type="submit"
                        class="btn default-btn-one"
                    >
                        {{ translate.instant("login") }}
                    </button>
                </div>
            </form>
        </div>
    </div>
</div>
<!-- End Sign in  Area -->
<div
    id="BranchModel"
    class="modal fade show"
    tabindex="-1"
    role="dialog"
    aria-labelledby="BranchModelLabel"
    aria-hidden="true"
>
<app-branches></app-branches>
</div>