<!-- Page banner Area -->
<div class="page-banner bg-1">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="page-content">
                    <h2>{{translate.instant('ContactWithUs')}}</h2>
                    <ul>
                        <li><a routerLink="/">{{translate.instant('MainPage')}}</a></li>
                        <li>{{translate.instant('ContactUs')}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Page banner Area -->

<!-- Conatct Info -->
<div class="pt-100 pb-70">
    <div class="container">
        <div class="row justify-content-center">
           <div class="col-xl-8 col-lg-9 col-12">
            <div class="card">
               <div class="card-body">
                <div class="contact-wrapper">
                    <div class="contact-box">
                        <a href="tel:0557773986">
                            <div class="icon">
<i class="fab fa-whatsapp"></i>
                            </div>
                            <div class="info">
                                {{translate.instant('Whatsapp')}}
                            </div>
                        </a>

                    </div>
                    <div class="contact-box">
                        <a href="tel:0112130011">
                            <div class="icon">
<i class="fa fa-phone-volume"></i>
                            </div>
                            <div class="info">
                                {{translate.instant('Telephone')}}
                            </div>
                        </a>

                    </div>
                    <div class="contact-box">
                        <a href="mailto:info@manarcare.com">
                            <div class="icon">
<i class="far fa-envelope"></i>
                            </div>
                            <div class="info">
                                {{translate.instant('Email')}}
                            </div>
                        </a>

                    </div>
                    <div class="contact-box">
                        <a href="mailto:info@manarcare.com">
                            <div class="icon">
<i class="far fa-paper-plane"></i>
                            </div>
                            <div class="info">
                                {{translate.instant('Complain')}}
                            </div>
                        </a>

                    </div>
                </div>
               </div>
            </div>
           </div>
        </div>
    </div>
</div>
<!-- End Conatct Info -->

<!-- Contact Area -->
<!-- <div class="contact-form-area pb-100">
    <div class="container">
        <div class="section-title">
            <span>{{translate.instant('ContactUs')}}</span>
            <h2> {{translate.instant('GetInTouch')}}</h2>
        </div>

        <div class="contact-form">
            <form id="contactForm">
                <div class="row">
                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" name="name" class="form-control" id="name" required placeholder="{{translate.instant('YourName')}}">
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="email" name="email" class="form-control" id="email" required placeholder="{{translate.instant('YourEmail')}}">
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="{{translate.instant('YourSubject')}}">
                        </div>
                    </div>

                    <div class="col-md-6 col-md-6">
                        <div class="form-group">
                            <input type="text" name="phone_number" class="form-control" id="phone_number" required placeholder="{{translate.instant('YourPhoneNo')}}">
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="form-group">
                            <textarea name="message" id="message" class="form-control" cols="30" rows="4" required placeholder="{{translate.instant('YourMsg')}}...."></textarea>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12 text-center">
                        <button type="submit" class="default-btn-one">{{translate.instant('SendMsg')}}</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div> -->
<!-- Contact Area -->

<!-- Newsletter Area -->
<!-- <div class="newsletter-area">
    <div class="container">
        <div class="newsletter-content">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="newsletter-title">
                        <h3>Subscribe to our newsletter:</h3>
                        <p>Focused on the transport and logistic industry</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form class="newsletter-form">
                        <input type="email" class="form-control" placeholder="Enter your email" name="EMAIL" required="" autocomplete="off">
                        <button type="submit" class="btn btn-primary">Subscribe</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- End Newsletter Area -->
