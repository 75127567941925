import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationExtras, Router } from '@angular/router';
import { VisitClient } from 'src/app/Services/Visit.service';
import { getPatient } from 'src/app/Functions/Utilities';
import swal from 'sweetalert';

@Component({
    selector: 'app-vacations',
    templateUrl: './vacations.component.html',
    styleUrls: ['./vacations.component.scss'],
})
export class VacationsComponent implements OnInit {
    Vacations: any[] = [];
    constructor(
        public translate: TranslateService,
        private route: Router,
        private visitClient: VisitClient
    ) {}

    get_visit_GetSickleave() {
        const appParam = {
            Patient_Code: getPatient()?.Patient_Code,
            Type: '',
        };
        this.visitClient
            .visit_GetSickleave(appParam)
            .subscribe((response: any) => {
                this.Vacations = response.Sickleave;
            });
    }
    getDoctorName(Vacation: any) {
        return this.translate.currentLang == 'en'
            ? Vacation?.DoctorNameEn ?? ''
            : Vacation?.DoctorNameAr ?? '';
    }
    getClinicDeptName(Vacation: any) {
        return this.translate.currentLang == 'en'
            ? Vacation?.ClinicDept_Name_En ?? ''
            : Vacation?.ClinicDept_Name_Ar ?? '';
    }
    convertDate(Visit_Date: any) {
        const Visit_Date_Split = Visit_Date.split('/');
        const day = Visit_Date_Split[0];
        const month = Visit_Date_Split[1];
        const year = Visit_Date_Split[2];
        return month + '-' + day + '-' + year;
    }
    visit_GetPatientVisits(Vacation:any) {
        const appParam = {
            PatientCode: getPatient()?.Patient_Code,
            EntryDate: this.convertDate(Vacation?.Visit_Date),
            DoctorCode: Vacation.DoctorCode,
            IslangArabic:this.translate.currentLang == 'en'? 0:1,
        };
        this.visitClient
            .visit_Report_PatientVisit(appParam)
            .subscribe((response: any) => {
                if (response._Url != '') window.open(response._Url, '_blank');
                else
                    swal({
                        title: this.translate.instant('NoData'),
                        icon: 'error',
                        timer: 2000,
                        buttons: {},
                    });
            });
    }
    ngOnInit(): void {
        this.get_visit_GetSickleave();
    }
}
