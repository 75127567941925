import { LocalStorageEnums } from '../Enums/LocalStorageEnums.enum';
export function getPatient():any {
    let patient!: object;
    patient = {
        Patient_Code: localStorage.getItem(LocalStorageEnums.Patient_Code),
        Email: localStorage.getItem(LocalStorageEnums.Email),
        ID_Number: localStorage.getItem(LocalStorageEnums.ID_Number),
        User_Name: localStorage.getItem(LocalStorageEnums.User_Name),
        Patient_Mobile: localStorage.getItem(LocalStorageEnums.Patient_Mobile),
        Patient_Age_Year: localStorage.getItem(
            LocalStorageEnums.Patient_Age_Year
        ),
        Patient_Name_Ar: localStorage.getItem(
            LocalStorageEnums.Patient_Name_Ar
        ),
        IMG: localStorage.getItem(
            LocalStorageEnums.Imagepath)
    };
    return patient;
}

export function sessionIsExpired() {
    let SessionExpireDate = new Date(
        localStorage.getItem(LocalStorageEnums.SessionExpireDate)!
    );
    let sessionDuration = Number(
        localStorage.getItem(LocalStorageEnums.sessionDuration) ?? 0
    );
    let ExpireDate = new Date(
        SessionExpireDate.getTime() + sessionDuration * 60000
    );
    return new Date(ExpireDate!).getTime() < Date.now();
}
export function saveUserInfoToStorage(response: any, Img: string = "", storeExpireinfo = true) {
    if (storeExpireinfo) {
        localStorage.setItem(
            LocalStorageEnums.SessionExpireDate,
            response?.SessionExpireDate!
        );
     
        localStorage.setItem(
            LocalStorageEnums.sessionDuration,
            response?.sessionDuration!
        );
    }
    localStorage.setItem(
        LocalStorageEnums.Imagepath,
        Img!
    );
    localStorage.setItem(
        LocalStorageEnums.Patient_Code,
        response?.patient[0].Patient_Code
    );
    localStorage.setItem(LocalStorageEnums.Email, response?.patient[0].Email);
    localStorage.setItem(
        LocalStorageEnums.ID_Number,
        response?.patient[0].ID_Number
    );
    localStorage.setItem(
        LocalStorageEnums.Country,
        response?.patient[0].Country
    );
    localStorage.setItem(
        LocalStorageEnums.Patient_Mobile,
        response?.patient[0]?.Patient_Mobile!
    );

    localStorage.setItem(
        LocalStorageEnums.Patient_Age_Year,
        response?.patient[0]?.Patient_Age_Year!
    );
    localStorage.setItem(
        LocalStorageEnums.User_Name,
        response?.patient[0].User_Name
    );
    localStorage.setItem(
        LocalStorageEnums.Patient_Name_Ar,
        response?.patient[0].Patient_Name_Ar
    );
}
export function saveBranchId(
    BranchId
) {
        localStorage.setItem(LocalStorageEnums.BranchId, BranchId);
}

