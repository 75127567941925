<div class="gallery-area pt-100 pb-70 mt-lg-3 mt-5">
    <div class="container">
        <div class="row" *ngIf="PatientVaccinationList.length!=0">
            <div class="col-12">
                <div class="Vacci_wrapper">
                    <div class="Vacci_Box" *ngFor="let PatientVaccination of PatientVaccinationList">
                        <p class="">
                            <label class="checbx_container w-100">{{getVacc_Name(PatientVaccination!)}}
                                <input type="checkbox" disabled="true"
                                [checked]="PatientVaccination?.IsDone">
                                <span class="checkmark"></span>
                              </label>
                             </p>
                        </div>

                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb-2 NoData_Div" *ngIf="PatientVaccinationList.length==0">
                    <div class="card">
                        <div class="card-body">
                            <div class="img">
                                <img
                                    src="../../../../../assets/img/no-data.png"
                                />
                            </div>
                            <div class="txt mt-3 mb-4">
                                <p class="mb-0">
                                    {{
                                        translate.instant(
                                            "NoData"
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
