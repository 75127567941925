<div class="gallery-area pt-100 pb-70 mt-lg-3 mt-5">
    <div class="container">
        <div class="row" *ngIf="Vacations.length!=0">
            <div class="col-12">
                <div class="Vaca_wrapper">

                    <div class="Vaca_Box" *ngFor="let Vacation of Vacations">
                        <div class="right">
                            <h5 class="doc-name ">{{getDoctorName(Vacation)}}</h5>
                            <p class="clinic-name mb-0">{{getClinicDeptName(Vacation)}}</p>
                            <p class="date mb-0">{{Vacation.Visit_Date}}</p>
                        </div>
                        <div class="left">
                            <p class="report-pdf mb-0">
                                <strong>{{translate.instant("DoctorReport")}}</strong>
                                <button (click)="visit_GetPatientVisits(Vacation)">
                                    <i class="fa fa-file-pdf"></i>
                                </button>
                            </p>
                            <p class="date">
                                {{Vacation.FullDate}}
                            </p>
                        </div>
                    </div>

                </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb-2 NoData_Div" *ngIf="Vacations.length==0">
                    <div class="card">
                        <div class="card-body">
                            <div class="img">
                                <img
                                    src="../../../../../assets/img/no-data.png"
                                />
                            </div>
                            <div class="txt mt-3 mb-4">
                                <p class="mb-0">
                                    {{
                                        translate.instant(
                                            "NoData"
                                        )
                                    }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
